<template>
  <div style="display: contents">
    <v-btn
      color="color3 color3Text--text"
      fab right
      :fixed="!isIntersecting"
      :bottom="!isIntersecting"
      @click.stop="open"
    >
      <v-icon>fas fa-plus</v-icon>
    </v-btn>

    <v-navigation-drawer
      right
      v-model="isOpen"
      fixed temporary
      :width="$vuetify.breakpoint.xs ? 350 : 500"
      :permanent="dirty"
    >
      <!-- HEADER -->
      <template v-slot:prepend>
        <v-toolbar color="color1 color1Text--text" tile dense>
          <v-toolbar-title class="white--text">Toolbar</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color2 color2Text--text"
            fab
            small
            @click.stop="cancel(true)"
          >
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <!-- FOOTER -->
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
            <v-col cols="6">
              <v-btn
                :color="activeBtn.color"
                :disabled="activeBtn.disabled"
                :loading="activeBtn.loading"
                @click.stop="activeBtn.onClick"
                block
                class="py-9"
              >
                {{ activeBtn.text }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="error white--text"
                @click.stop="activeBtn.cancel"
                :disabled="loading"
                block
                class="py-9"
              >
              {{activeBtn.cancelText || 'Cancel'}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
      <!-- BODY -->
      <v-card-text :class="{ 'px-0': window === 1 }">
        <v-container class="pt-0 px-0">
          <v-row dense>
            <v-col cols="12">HERE</v-col>
          </v-row>
          <v-window v-model="window">
            <v-window-item :key="0">
              <v-row dense>
                <v-col cols="12" class="text-h4">
                  HERE
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-container>
        <v-overlay color="white" v-if="loading">
          <div class="text-center">
            <v-progress-circular indeterminate color="color3" size="64"></v-progress-circular>
          </div>
          <div class="color3--text text-center text-h4">Loading</div>
        </v-overlay>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { timeOptions } from '@/classes/HelperFunctions'
import { localDate, localDt } from '@/Filters'
import { darkerColor } from '@/classes/Dark.js'
// import { roundUpToHour } from '@/helpers/DateRounding.js'

export default {
  props: ['isIntersecting', 'myEvent'],
  data () {
    return {
      isOpen: false,
      loading: false,
      iEvent: {},
      window: 0
    }
  },
  computed: {
    ...mapGetters(['user', 'tournament', 'theme']),
    isNew () {
      return this.iEvent && !this.iEvent.id
    },
    activeBtn () {
      return {
        color: 'success white--text',
        icon: 'fas fa-times',
        onClick: this.postRatings,
        text: 'save',
        cancel: this.cancel,
        disabled: !this.valid,
        cancelText: this.dirty ? 'Cancel' : 'close'
      }
    },
    dirty () {
      const i = this.iEvent
      return this.isNew ? !!(i.rating1 || i.rating2 || i.comments) : JSON.stringify(this.selectedRatting) !== JSON.stringify(i)
    },
    valid () {
      const i = this.iEvent
      return i.rating1 && i.rating2
    },
    times () {
      return timeOptions()
    },
    cityState () {
      if (this.player) {
        const a = []
        this.player.city && a.push(this.player.city)
        this.player.state && a.push(this.player.state)
        return a.join(', ')
      }
      return null
    },
    showMetrics () {
      return this.player && this.player.showMetrics((this.view || this.iView), this.user)
    },
    dto () {
      const i = this.iRating
      return {
        id: i.id,
        playerProfileId: this.player.id,
        rating: `${i.rating1}${i.rating2}`,
        comments: i.comments
      }
    }
  },
  methods: {
    getColor (i) {
      return i % 2 === 0 ? '#152047' : '#ca9700'
    },
    displayDate (d) {
      return localDate(d)
    },
    displayDateTime (d) {
      return localDt(d)
    },
    init () {
      if (this.myEvent) {
        this.loadEvent()
      } else {
        this.edit()
      }
    },
    loadEvent () {
      this.$store.dispatch('LOAD_TOURNAMENT', { tournamentId: this.myEvent.id })
    },
    edit () {
      const e = this.myEvent
      this.iEvent = {
        id: e ? e.id : 0,
        name: e ? e.name : null,
        start: e ? e.start : null,
        end: e ? e.end : null
      }
    },
    cancel (close) {
      // this.init()
      if (this.isNew || close === true) this.isOpen = false
      this.window = 0
    },
    open () {
      this.isOpen = true
    },
    postEvent () {
      this.loading = true
      this.$VBL.user.ntdp.post([this.dto])
        .then(r => {
          this.setHistory(r.data)
          this.saved = true
          this.init()
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    getDarkerColor (color1, color2) {
      return darkerColor(color1, color2)
    }
  },
  watch: {
    isOpen: function (val) {
      if (!val) {
        this.cancel()
      } else {
        this.init()
      }
    },
    window: function (val) {
      if (val === 1) this.getUsage()
    }
  },
  components: {
  }
}
</script>
